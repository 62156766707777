var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcSlider from 'rc-slider/lib/Slider';
import RcRange from 'rc-slider/lib/Range';
import RcHandle from 'rc-slider/lib/Handle';
import classNames from 'classnames';
import SliderTooltip from './SliderTooltip';
import { ConfigConsumer } from '../config-provider';
const Slider = React.forwardRef((props, ref) => {
    const [visibles, setVisibles] = React.useState({});
    const toggleTooltipVisible = (index, visible) => {
        const temp = Object.assign({}, visibles);
        temp[index] = visible;
        setVisibles(temp);
    };
    const handleWithTooltip = (_a) => {
        var { tooltipPrefixCls, prefixCls } = _a, _b = _a.info, { value, dragging, index } = _b, restProps = __rest(_b, ["value", "dragging", "index"]);
        const { tipFormatter, tooltipVisible, tooltipPlacement, getTooltipPopupContainer, vertical, } = props;
        const isTipFormatter = tipFormatter ? visibles[index] || dragging : false;
        const visible = tooltipVisible || (tooltipVisible === undefined && isTipFormatter);
        return (<SliderTooltip prefixCls={tooltipPrefixCls} title={tipFormatter ? tipFormatter(value) : ''} visible={visible} placement={tooltipPlacement || (vertical ? 'right' : 'top')} transitionName="zoom-down" key={index} overlayClassName={`${prefixCls}-tooltip`} getPopupContainer={getTooltipPopupContainer || (() => document.body)}>
        <RcHandle {...restProps} value={value} onMouseEnter={() => toggleTooltipVisible(index, true)} onMouseLeave={() => toggleTooltipVisible(index, false)}/>
      </SliderTooltip>);
    };
    const renderSlider = ({ getPrefixCls, direction }) => {
        const { prefixCls: customizePrefixCls, tooltipPrefixCls: customizeTooltipPrefixCls, range, className } = props, restProps = __rest(props, ["prefixCls", "tooltipPrefixCls", "range", "className"]);
        const prefixCls = getPrefixCls('slider', customizePrefixCls);
        const tooltipPrefixCls = getPrefixCls('tooltip', customizeTooltipPrefixCls);
        const cls = classNames(className, {
            [`${prefixCls}-rtl`]: direction === 'rtl',
        });
        // make reverse default on rtl direction
        if (direction === 'rtl' && !restProps.vertical) {
            restProps.reverse = !restProps.reverse;
        }
        if (range) {
            return (<RcRange {...restProps} className={cls} ref={ref} handle={(info) => handleWithTooltip({
                tooltipPrefixCls,
                prefixCls,
                info,
            })} prefixCls={prefixCls} tooltipPrefixCls={tooltipPrefixCls}/>);
        }
        return (<RcSlider {...restProps} className={cls} ref={ref} handle={(info) => handleWithTooltip({
            tooltipPrefixCls,
            prefixCls,
            info,
        })} prefixCls={prefixCls} tooltipPrefixCls={tooltipPrefixCls}/>);
    };
    return <ConfigConsumer>{renderSlider}</ConfigConsumer>;
});
Slider.defaultProps = {
    tipFormatter(value) {
        return typeof value === 'number' ? value.toString() : '';
    },
};
export default Slider;
