var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigConsumer } from '../config-provider';
const CheckableTag = props => {
    const handleClick = () => {
        const { checked, onChange } = props;
        if (onChange) {
            onChange(!checked);
        }
    };
    const renderCheckableTag = ({ getPrefixCls }) => {
        const { prefixCls: customizePrefixCls, className, checked } = props, restProps = __rest(props, ["prefixCls", "className", "checked"]);
        const prefixCls = getPrefixCls('tag', customizePrefixCls);
        const cls = classNames(prefixCls, {
            [`${prefixCls}-checkable`]: true,
            [`${prefixCls}-checkable-checked`]: checked,
        }, className);
        delete restProps.onChange; // TypeScript cannot check delete now.
        return <span {...restProps} className={cls} onClick={handleClick}/>;
    };
    return <ConfigConsumer>{renderCheckableTag}</ConfigConsumer>;
};
export default CheckableTag;
