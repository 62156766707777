import * as React from 'react';
import RcCollapse from 'rc-collapse';
import classNames from 'classnames';
import RightOutlined from '@ant-design/icons/RightOutlined';
import CollapsePanel from './CollapsePanel';
import { ConfigConsumer } from '../config-provider';
import animation from '../_util/openAnimation';
export default class Collapse extends React.Component {
    constructor() {
        super(...arguments);
        this.renderExpandIcon = (panelProps = {}, prefixCls) => {
            const { expandIcon } = this.props;
            const icon = (expandIcon ? (expandIcon(panelProps)) : (<RightOutlined rotate={panelProps.isActive ? 90 : undefined}/>));
            return React.isValidElement(icon)
                ? React.cloneElement(icon, {
                    className: classNames(icon.props.className, `${prefixCls}-arrow`),
                })
                : icon;
        };
        this.renderCollapse = ({ getPrefixCls, direction }) => {
            const { prefixCls: customizePrefixCls, className = '', bordered } = this.props;
            const prefixCls = getPrefixCls('collapse', customizePrefixCls);
            const iconPosition = this.getIconPosition(direction);
            const collapseClassName = classNames({
                [`${prefixCls}-borderless`]: !bordered,
                [`${prefixCls}-icon-position-${iconPosition}`]: true,
                [`${prefixCls}-rtl`]: direction === 'rtl',
            }, className);
            const openAnimation = Object.assign(Object.assign({}, animation), { appear() { } });
            return (<RcCollapse openAnimation={openAnimation} {...this.props} expandIcon={(panelProps) => this.renderExpandIcon(panelProps, prefixCls)} prefixCls={prefixCls} className={collapseClassName}/>);
        };
    }
    getIconPosition(direction = 'ltr') {
        const { expandIconPosition } = this.props;
        if (expandIconPosition !== undefined) {
            return expandIconPosition;
        }
        return direction === 'rtl' ? 'right' : 'left';
    }
    render() {
        return <ConfigConsumer>{this.renderCollapse}</ConfigConsumer>;
    }
}
Collapse.Panel = CollapsePanel;
Collapse.defaultProps = {
    bordered: true,
};
