var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import omit from 'omit.js';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import CheckableTag from './CheckableTag';
import { ConfigConsumer } from '../config-provider';
import { PresetColorTypes, PresetStatusColorTypes, } from '../_util/colors';
import Wave from '../_util/wave';
export { CheckableTagProps } from './CheckableTag';
const PresetColorRegex = new RegExp(`^(${PresetColorTypes.join('|')})(-inverse)?$`);
const PresetStatusColorRegex = new RegExp(`^(${PresetStatusColorTypes.join('|')})$`);
const Tag = props => {
    const [visible, setVisible] = React.useState(true);
    React.useEffect(() => {
        if ('visible' in props) {
            setVisible(props.visible);
        }
    }, [props.visible]);
    const isPresetColor = () => {
        const { color } = props;
        if (!color) {
            return false;
        }
        return PresetColorRegex.test(color) || PresetStatusColorRegex.test(color);
    };
    const getTagStyle = () => {
        const { color, style } = props;
        return Object.assign({ backgroundColor: color && !isPresetColor() ? color : undefined }, style);
    };
    const getTagClassName = ({ getPrefixCls, direction }) => {
        const { prefixCls: customizePrefixCls, className, color } = props;
        const presetColor = isPresetColor();
        const prefixCls = getPrefixCls('tag', customizePrefixCls);
        return classNames(prefixCls, {
            [`${prefixCls}-${color}`]: presetColor,
            [`${prefixCls}-has-color`]: color && !presetColor,
            [`${prefixCls}-hidden`]: !visible,
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, className);
    };
    const handleIconClick = (e) => {
        e.stopPropagation();
        const { onClose } = props;
        if (onClose) {
            onClose(e);
        }
        if (e.defaultPrevented) {
            return;
        }
        if (!('visible' in props)) {
            setVisible(false);
        }
    };
    const renderCloseIcon = () => {
        const { closable } = props;
        return closable ? <CloseOutlined onClick={handleIconClick}/> : null;
    };
    const renderTag = (configProps) => {
        const { children, icon } = props, otherProps = __rest(props, ["children", "icon"]);
        const isNeedWave = 'onClick' in otherProps || (children && children.type === 'a');
        const tagProps = omit(otherProps, ['onClose', 'color', 'visible', 'closable', 'prefixCls']);
        const iconNode = icon || null;
        const kids = iconNode ? (<>
        {iconNode}
        <span>{children}</span>
      </>) : (children);
        return isNeedWave ? (<Wave>
        <span {...tagProps} className={getTagClassName(configProps)} style={getTagStyle()}>
          {kids}
          {renderCloseIcon()}
        </span>
      </Wave>) : (<span {...tagProps} className={getTagClassName(configProps)} style={getTagStyle()}>
        {kids}
        {renderCloseIcon()}
      </span>);
    };
    return <ConfigConsumer>{renderTag}</ConfigConsumer>;
};
Tag.defaultProps = {
    closable: false,
};
Tag.CheckableTag = CheckableTag;
export default Tag;
